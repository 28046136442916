<template>
    <aw-tabler :table="table">
        <template #filter>
            <el-form :model="table.filter" inline label-suffix="：">
                <el-form-item label="提现单号" prop="trade_no">
                    <el-input v-model="table.filter.trade_no" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="订单编号" prop="order_no">
                    <el-input v-model="table.filter.order_no" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="代理商手机号" prop="username">
                    <el-input v-model="table.filter.username" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="提现状态" prop="status">
                    <el-select v-model="table.filter.status" placeholder="请选择">
                        <el-option v-for="opt in statusOpt" :key="opt.value" :label="opt.label" :value="opt.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="分账时间" prop="bet_time">
                    <el-date-picker v-model="table.filter.bet_time" type="datetimerange" format="YYYY-MM-DD HH:mm:ss" value-format="x" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="完成时间" prop="final_time">
                    <el-date-picker v-model="table.filter.final_time" type="datetimerange" format="YYYY-MM-DD HH:mm:ss" value-format="x" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                </el-form-item>
            </el-form>
        </template>
        <template #drawercontent>
            <div v-if="table.drawer.flex == 'remark'">
                <Remark
                    :modelInfo="table.drawer.form"
                    @close-drawer="
                        () => {
                            table.drawer.show = false;
                            table.page.currentChange(1);
                        }
                    " />
            </div>
        </template>
    </aw-tabler>
</template>

<script setup lang="jsx">
import { ref, reactive } from 'vue';
import { tableColumn } from '@/assets/js/extend/tableColumn';
import { http, tool, util } from '@/assets/js';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import Remark from './Remark.vue';

const route = useRoute();
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))


const statusOpt = ref([
    { value: '', label: '全部' },
    { value: '-1', label: '待处理' },
    { value: '1', label: '提现成功' },
    { value: '2', label: '提现失败' },
]);

const cashType = ref({
    1: '微信',
});

const cashUserType = ref({
    1: '商户号',
    2: '个人微信',
});

// const today = new Date();
// const fullyear = today.getFullYear();
// const month = today.getMonth();
// const date = today.getDate();

const table = ref({
    title: '代理商账单',
    loading: false,
    filter: {
        trade_no: '',
		order_no: '',
		username: '',
		status: '',
        // bet_time: [new Date(fullyear, 0, 1, 0, 0, 0), new Date(fullyear, month, date, 23, 59, 59)],
        bet_time: [],
        final_time: [],
    },
    datas: [],
    columns: [
        ...tableColumn([
            {
                title: '提现单号',
                key: 'trade_no',
                minWidth: '210px',
            },
            {
                title: '订单编号',
                key: 'order_no',
                minWidth: '210px',
            },
            {
                title: '代理商信息',
                key: 'name',
                minWidth: '180px',
                render: (data) => {
                    return (
                        <span>
                            代理商：{data.name + '\n'}手机号：{data.username + '\n'}
                        </span>
                    );
                },
            },
            {
                title: '提现金额',
                key: '',
                minWidth: '100px',
                render: (data) => {
                    return <span>{(+data.meet_price / 100).toFixed(2)}</span>;
                },
            },
            {
                title: '到账金额',
                key: 'pay_price',
                minWidth: '100px',
                render: (data) => {
                    return <span>{(+data.pay_price / 100).toFixed(2)}</span>;
                },
            },
            {
                title: '预计分账时间',
                key: 'bet_time',
                minWidth: '120px',
                render: (data) => {
                    return <span>{util.format(data.bet_time * 1000, 'yyyy-MM-DD')}</span>;
                },
            },
            {
                title: '完成时间',
                key: 'final_time',
                minWidth: '180px',
                time: true,
            },
            {
                title: '提现方式',
                key: 'cash_type',
                minWidth: '100px',
                render: (data) => {
                    return <span>{data.cash_type ? cashType.value[data.cash_type] : ''}</span>;
                },
            },
            {
                title: '提现账户',
                key: 'cash_user_type',
                minWidth: '100px',
                render: (data) => {
                    return <span>{data.cash_user_type ? cashUserType.value[data.cash_user_type] : ''}</span>;
                },
            },
            {
                title: '账户信息',
                key: 'cash_user_info',
                minWidth: '200px',
                render: (data) => {
                    if(!data.cash_user_type) return ''
                    return (
                        <div>
                            {data.cash_user_type == 1 ? `商户号\n${data.cash_user_info.cash_user_id}` : `${data.cash_user_info.realname}\n(ID: ${data.cash_user_info.id})`}
                        </div>
                    )
                },
            },
            {
                title: '提现状态',
                key: 'status',
                minWidth: '100px',
                render: (data)=> {
                    const label = data.status ? statusOpt.value.find(({ value }) => value == data.status).label : '';
                    return <span>{label}</span>;
                },
            },
            {
                title: '备注',
                key: 'remark',
                minWidth: '200px',
                render: (data)=> {
                    if(!data.remark) return ''
                    return (
                        <el-popover placement={'top'} width={'200'} trigger={'hover'} content={data.remark} v-slots={{ reference: () => <span class={'no-warp-5 c-p'}>{data.remark}</span> }}
                        ></el-popover>
                    )
                }
            },
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '160px',
            buttons: [
                {
                    name: '备注',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('txjk_bz'),
                    action: (data) => {
                        table.value.drawer.show = true;
                        table.value.drawer.title = '备注';

                        table.value.drawer.flex = 'remark';
                        table.value.drawer.form = {
                            trade_no: data.trade_no,
                            remark: data.remark,
                        };
                    },
                },
            ],
        },
    ],
    page: {
        total: 0,
        page: 1,
        page_size: 10,
        sizeChange: (val) => {
            table.value.page.page_size = val;
            table.value.page.page = 1;
            table.value.action(table.value.filter);
        },
        currentChange: (val) => {
            table.value.page.page = val;
            table.value.action(table.value.filter);
        },
    },
    action: async (params) => {
        if(!powerlimits.includes('txjl_lb')) return
        table.value.loading = true;
        let page = { page: table.value.page.page, page_size: table.value.page.page_size };
        const bet_time = table.value.filter.bet_time ? table.value.filter.bet_time.map((i) => i / 1000).join(' ~ ') : '';
        const final_time = table.value.filter.final_time ? table.value.filter.final_time.map((i) => i / 1000).join(' ~ ') : '';
        http.post('admin/cashflow/orderSplitIndex', {
            ...params,
            ...route.query,
            ...page,
            bet_time,
            final_time,
        })
            .then((result) => {
                table.value.datas = result.data;
                table.value.page.total = result.total || Infinity;
                table.value.page.page = result.current_page;
                table.value.page.page_size = result.per_page;
            })
            .finally(() => {
                table.value.loading = false;
            });
    },
    extras: [
        {
            name: '导出',
            props: { type: 'warning', plain: true },
            show: () => powerlimits.includes('txjl_dc'),
            action: () => {
                let page = { page: table.value.page.page, page_size: table.value.page.page_size };
                const bet_time = table.value.filter.bet_time ? table.value.filter.bet_time.map((i) => i / 1000).join(' ~ ') : '';
                const final_time = table.value.filter.final_time ? table.value.filter.final_time.map((i) => i / 1000).join(' ~ ') : '';
                http.post('admin/cashflow/orderSplitIndex', {
                    ...table.value.filter,
                    ...route.query,
                    ...page,
                    bet_time,
                    final_time,
                    export: 1,
                }).then((res) => {
                    if (res.code === 0) ElMessage.success(res.message);
                });
            },
        },
    ],
    drawer: reactive({
        flex: null,
        form: {},
        show: false,
        title: '',
        close: () => {
            table.value.drawer.show = false
        }
    })
});
</script>

<template>
    <div>
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
            <el-form-item label="备注信息：" prop="remark">
                <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入" clearable maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="saveEvent('ruleFormRef')" :disabled="disabled_btn">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { ref } from 'vue'
import { http } from '@/assets/js'

export default {
    name: 'Remark',
    inject: ['reload'],
    props: { modelInfo: { type: Object, required: true } },
    watch: {
        modelInfo: {
            handler(newVal) {
                this.ruleForm = newVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            ruleForm: {},
            rules: {
                remark: [
                    {
                        required: true,
                        message: '备注信息为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            disabled_btn: false
        }
    },
    methods: {
        saveEvent(formName) {
			let { trade_no, remark } = this.ruleForm
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.disabled_btn = true
                    await http.post(`admin/cashflow/orderSplitRemark`, { trade_no, remark }, { sMsg: true } )
                    this.$emit('close-drawer', true)
                    this.$refs[formName].resetFields()
                    this.disabled_btn = false
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped></style>
